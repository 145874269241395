import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RedirectUserProfile from 'components/redirects/profile/RedirectUserProfile';
import TargetStore from 'components/redirects/profile/TargetStore';

const Root = (props) => (
	<Router {...props}>
		<div className="app-root">
			<Switch>
                <Route path="/ts/:nickname" component={TargetStore} />
                <Route path="/l/:nickname" component={RedirectUserProfile} />
				<Route path="/:nickname" component={RedirectUserProfile} />
                <Route exact path='/' component={() => { 
                    window.location.href = process.env.REACT_APP_WEB_APP_DOMAIN + '/explore'; 
                    return null;
                }}/>
			</Switch>
		</div>
	</Router>
);

export default Root;